.title {
  color: #333;
  padding-left: 10px;
  margin-top: 7px;
  border-left: 4px solid #1e7cc4;
}

.body-children {
  margin-left: 32px;
}

.title-h6 {
  margin-bottom: 22px;
  margin-top: 15px
}

h4 {
  margin: 20px 0 10px 0;
  font-size: 1.5rem;
  color: #646c9a
}

table {
  caption {
    caption-side: top;
    font-size: 16px;
    color: #646c9a;
  }

  tr {
    td.key {
      font-weight: 700;
      width: 250px;
      font-size: 1.1rem;
    }

    td.value {
      font-size: 1.1rem;
      text-align: left;
    }
  }
}
