$fontSize: 1.2rem;

.navbar-dark .navbar-nav .nav-link {
  margin-left: 10px;
  margin-right: 10px;
  font-size: $fontSize;
}

h1, h2, h3 {
  color: #646c9a;
}

p {
  color: #646c9a;
  font-size: $fontSize;
}

footer {
  i {
    color: #646c9a;
  }
}

$hawis-main: #3557d3;

.bg-hawis {
  background: $hawis-main;
}

.text-hawis {
  color: $hawis-main
}
