@import "../../../_metronic/_assets/sass/pages/wizard/wizard-2.scss";

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon i.fa,
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon i.fas {
  font-size: 2.5rem;
}

$activeBg: #3557d3;

.wizard {
  &.wizard-2 {
    .wizard-nav {
      .wizard-steps {
        .wizard-step {
          cursor: pointer;

          &[data-wizard-state=current] {
            background: $activeBg;

            &:after {
              border-left-color: $activeBg;
            }

            .wizard-icon i {
              color: white !important;
            }

            .wizard-label .wizard-title {
              color: white;
            }
          }

        }
      }
    }
  }

  h3 {
    margin-bottom: 0
  }
}
