@import "../../admin/student/student.scss";

.display-block {
  display: block !important;
}

.register-children {
  h1 {
    font-size: 3.5rem;
  }
}

.react-datepicker-wrapper {
  display: block !important;
}
